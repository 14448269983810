import React, { createContext } from "react"
import firebase from "gatsby-plugin-firebase"

// we create a React Context, for this to be accessible
// from a component later
const FirebaseContext = createContext(null);
export { FirebaseContext };

export default ({ children }) => {
  const saveEmail = async (email) => {
    return await firebase.firestore().collection('emails').add({
      email,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  const firebaseConfig = {
    api: {
      saveEmail,
    },
  };

  return <FirebaseContext.Provider value={firebaseConfig}>{children}</FirebaseContext.Provider>;
};
