import React, { useContext } from "react"
import ScrollAnimation from 'react-animate-on-scroll'

import content from '../../content/subscribe.yaml'
import { FirebaseContext } from "../providers/Firebase/firebase"



export default () => {
  const {app, api} = useContext(FirebaseContext)
  const onSend = (e) => {
    const txEmail = document.getElementById('register-email')
    if (txEmail.value.trim() === '' || !txEmail.checkValidity()) {
      return;
    }
    const result = api.saveEmail(txEmail.value.trim())
    txEmail.value = ''
    txEmail.placeholder = 'Thank you!'
    document.getElementById('send-email').enabled = false
  }

  return (
    <section id="subscribe">
      <div className="row section-head">
        <div className="twelve columns">
          <h1>{content.title}</h1>
          <p>{content.body}</p>
        </div>
      </div>

      <div className="row">
        <div className="twelve columns">
          <div id="mc_embed_signup">
            <form
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
            >
              <ScrollAnimation animateIn="fadeInLeftBig" animateOnce={true}>
                <input
                  type="email"
                  defaultValue=""
                  name="EMAIL"
                  className="email"
                  id="register-email"
                  placeholder="Your best email"
                  required
                />
              </ScrollAnimation>
              {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
              <div style={{ position: "absolute", left: "-5000px" }}>
                <input type="text" name="b_cdb7b577e41181934ed6a6a44_e65110b38d" defaultValue="" />
              </div>
              <div className="clear">
                <ScrollAnimation
                  animateIn="fadeInRightBig"
                  animateOnce={true}
                >
                  <input
                    type="button"
                    value="Register"
                    name="subscribe"
                    id="send-email"
                    className="button"
                    onClick={onSend}
                  />
                </ScrollAnimation>
              </div>

            </form>
          </div>

          <p><small>{content.small}</small></p>

        </div>

      </div>

    </section>
  )
}
