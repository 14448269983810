import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import content from "../../content/features.yaml"

export default () => (

  <section id="features">
    {content.map((value, index) => {

      let pos, imagepos
      if (index % 2 === 0) {
        pos = "right"
        imagepos = "left"
      } else {
        pos = "left"
        imagepos = "right"
      }

      let media
      if (value.image) {
        media = (<img src={value.image} alt={value.title} />)
      } else if (value.youtube) {
        media = (
          <div className="fluid-video-wrapper">
            <iframe
              title="video"
              src={`https://www.youtube.com/embed/83QCQqxFJSs?controls=0`}
              width="500" height="481" frameBorder="0" webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            >
            </iframe>
          </div>
        )
      }

      return (
        <div className={"row feature " + value.name}>
          <div className={"six columns " + pos}>
            <h3>{value.title}</h3>
            <p>{value.body}</p>
            {value.lines ? <ol>{value.lines.map(line => {
              return <li>{line}</li>
            })}</ol> : null}
          </div>

          <ScrollAnimation
            animateIn="pulse"
            animateOnce={true}
            initiallyVisible={true}
            className={"six columns feature-media " + imagepos}
          >
            {media}
          </ScrollAnimation>
        </div>
      )
    })}
      </section>
      )
